import WheelFortune from 'wheel-fortune';
import DialogLite from 'dialog-lite';

import './data/translations.js';

const App = {
  wheelFortune: null,
  dialogLite: null,

  initWheelFortune() {
    const firstSpin = () => {
      document.body.classList.add('first-spin-complete');
    };

    const secondSpin = () => {
      document.body.classList.add('second-spin-complete');

      setTimeout(() => {
        this.dialogLite.open({ stylingClass: 'dialog-lite--first' });
      }, 1500);
    };

    const spinStates = [
      { stopSegment: 3, callback: () => firstSpin() },
      { stopSegment: 2, callback: () => secondSpin() },
    ];

    this.wheelFortune = new WheelFortune({
      spinStates,
      rotationCount: 4,
      segmentCount: 6,
      wheelLibration: true,
      containerEl: '.wheel-fortune',
      segmentsEl: '.wheel-fortune__segments',
      buttonEl: '.wheel-fortune__button',
    });

    this.wheelFortune.init();
  },

  initDialogLite() {
    this.dialogLite = new DialogLite();
    this.dialogLite.init();
  },

  async init() {
    this.initWheelFortune();
    this.initDialogLite();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
